import Image from 'next/image';
import clsx from 'clsx';
import { Button } from '@/components/Button';
import { FullScreenLoading } from '@/components/FullScreenLoading';
import { Warning } from '@/components/Warning';
import { useEntry } from '../App.hooks';
import { ReviewStars } from './ReviewStars';
type Props = {
  className?: string;
};
export const AppCard = ({
  className
}: Props) => {
  const entry = useEntry();
  if ('error' in entry) {
    return <Warning className={className}>{entry.error}</Warning>;
  }
  if (entry.isLoading) return <FullScreenLoading />;
  const purchaseURL = `https://login.bigcommerce.com/deep-links/marketplace/apps/${entry.data?.appRegistryId}`;
  return <section data-testid="app-card" className={clsx('w-full', className)} data-sentry-component="AppCard" data-sentry-source-file="AppCard.tsx">
			<div className={`min-w-[200px] w-[90%] lg:w-full max-w-5xl mx-auto flex flex-col md:flex-row items-start md:items-center bg-white shadow-lg rounded-xl relative p-9 gap-4 ${className}`}>
				{/* Image */}
				{entry.data?.logoIcon && <Image src={entry.data.logoIcon?.startsWith('//') ? `https:${entry.data.logoIcon}` : entry.data.logoIcon!} alt={entry.data.name || 'App logo'} width={50} height={50} />}

				{/* Text Content */}
				<div className="md:ml-6 flex flex-col">
					<h1 className="text-bc-black text-xl sm:text-2xl font-medium my-2 md:my-0">
						{entry.data?.name}
					</h1>

					{typeof entry.data?.reviewCount === 'number' && entry.data?.reviewCount > 0 && typeof entry.data?.rating === 'number' && <div className="flex max-lg:flex-col">
								<ReviewStars rating={entry.data.rating} className="mr-1" />

								<span className="text-xxs text-gray-500">
									{entry.data.rating.toFixed()} out of 5 stars with {entry.data?.reviewCount}{' '}
									reviews
								</span>
							</div>}
				</div>

				{/* Buttons */}
				<div className="flex flex-col gap-4 md:flex-row md:ml-auto text-xs font-medium text-center">
					<Button link={{
          href: purchaseURL
        }} variant="filled" color="secondary" className="justify-center" dataTestId="get-app-btn" data-sentry-element="Button" data-sentry-source-file="AppCard.tsx">
						Get This App
					</Button>

					{entry.data?.supportUrl && <Button link={{
          href: entry.data.supportUrl
        }} variant="outlined" color="secondary" className="justify-center" dataTestId="contact-partner-btn">
							Contact Partner
						</Button>}
				</div>
			</div>
		</section>;
};